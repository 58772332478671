<template>
	<div class="social">
		<ul>
			<li
				v-for="({link, name, slug}) in items"
				:key="name"
				:class="slug"
			>
				<a
					:href="link"
					rel="noopener noreferrer"
					target="_blank"
					:title="`More on ${name}`"
				>
					<svg>
						<use :xlink:href="`#${slug}--sprite`" />
					</svg>
					<span>{{ name }}</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import social from '@/data/social.json';

export default {
	data() {
		return {
			items: social.map(el => {
				el.icon = require(`@/assets/icons/${el.slug}.svg?sprite`);
				return el;
			})
		};
	}
};
</script>

<style lang="scss" scoped>
	.wrap {
		margin: var(--space-10) auto;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {
		padding: 0.5rem;
	}

	a {
		transition: color var(--transition);
		color: var(--color-highlight);
		text-decoration: none;

		&:hover,
		&:focus {
			color: var(--color-brand);
		}
	}

	span {
		@include sr-only();
	}

	@each $name, $color in $color-social {
		.#{$name} {
			--color-brand: #{$color};
		}
	}

	.dark .github {
		--color-brand: #fff;
	}
</style>
